import React, { useRef } from "react";
import {
  faArrowCircleLeft,
  faDownload,
  faHistory,
  faHome,
  // faCheck,
  // faCog,
  // faHome,
  // faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ButtonGroup,
  Col,
  Row,
  // Form,
  // Button,
  // ButtonGroup,
  Breadcrumb,
  // InputGroup,
  // Dropdown,
} from "@themesberg/react-bootstrap";

import { TransactionsTable } from "../components/Tables2";

import DaterangeSelector from "./dashboard/DaterangeSelector";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { _fetchApi, _postApi } from "../redux/action/api";
import { toast } from "react-hot-toast";
import { useCallback } from "react";
import { useQuery } from "../utils";
import { SearchBar } from "../components/UI";

import { downloadExcel } from "react-export-table-to-excel";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LegderPDF from "../components/LegderPDF";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import { TAX_ACCOUNT } from "../redux/action/type";

export default ({ taxPayer = {}, viewOnly = false }) => {
  const tableRef = useRef(null);
  const today = moment().format("YYYY-MM-DD");
  const { tax_account, user } = useSelector((s) => s.auth);
  const [trx, setTrx] = useState([]);
  const q = useQuery();
  const trx_ref = q.get("trx_ref");
  const _form = {
    from: moment(today).add(-1, "days").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    user_id: null,
  };
  const [form, setForm] = useState(_form);
  const [filterText, setFilterText] = useState("");
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const getTrx = useCallback(() => {
    if (form.user_id > 1 && !trx_ref) {
      _fetchApi(
        `/transactions/retrieve?query_type=view_payer_ledger&user_id=${form.user_id}&start_date=${form.from}&end_date=${form.to}`,
        (resp) => {
          setTrx(resp.data);
        },
        (err) => {
          setTrx([]);
          toast.error("Failed to retrieve transactions");
        }
      );
    } else if (trx_ref) {
      _fetchApi(
        `/transactions/retrieve?query_type=view_payer_ledger&user_id=${form.user_id}&start_date=${form.from}&end_date=${form.to}`,
        (resp) => {
          setTrx(resp.data);
        },
        (err) => {
          setTrx([]);
          toast.error("Failed to retrieve transactions");
        }
      );
    }
  }, [form.from, form.to, form.user_id, trx_ref]);

  useEffect(() => {
    getTrx();
  }, [getTrx]);

  const getUser = useCallback(() => {
    if (taxPayer.taxID && !form.user_id) {
      setForm((p) => ({ ...p, user_id: taxPayer.taxID }));
    } else if (tax_account.taxID && !form.user_id) {
      setForm((p) => ({
        ...p,
        user_id: tax_account.taxID,
      }));
    }
  }, [tax_account, taxPayer, form.user_id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const filtered_trx = filterText.length
    ? trx.filter(
        (item) =>
          item.description.toLowerCase().includes(filterText.toLowerCase()) ||
          item?.transaction_id.toString().includes(filterText)
      )
    : trx;

  const header = [
    "SN",
    "DATE",
    "Ref No.",
    "NIN",
    "TIN",
    "TAX ID",
    "DESCRIPTION",
    "AMOUNT",
    "STATUS",
  ];
  const RECORDS = trx?.length
    ? trx.map((item, idx) => ({
        id: idx + 1,
        date: item.transaction_date,
        ref_no: item.reference_number,
        nin: taxPayer.nin ? taxPayer.nin : tax_account?.nin,
        tin: taxPayer.tin ? taxPayer.tin : tax_account?.tin,
        tax_id: taxPayer.taxID ? taxPayer.taxID : tax_account?.taxID,
        description: item.description,
        amount: Math.max(item.dr),
        status: item.status,
      }))
    : [];

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "kirmas-user_ledger",
      sheet: "user_ledger",
      tablePayload: {
        header,
        body: RECORDS.filter((_) => _.amount > 0),
      },
    });
  }
  const payer = taxPayer.id ? taxPayer : tax_account;
  const history = useHistory();

  const [taxPayers, setTaxPayers] = useState([])
  const [org_name, setOrgName] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
      if (org_name.length > 2) {
          _fetchApi(
              `/users/search-tax-payers?user_id=${org_name}&max=50`,
              (resp) => {
                  if (resp.success) {
                      setTaxPayers(resp.data);
                  }
              }
          );
      } else if (org_name.length === 0) {
          _fetchApi(
              `/users/search-tax-payers?user_id=&max=50`,
              (resp) => {
                  if (resp.success) {
                      setTaxPayers(resp.data);
                  }
              }
          );
      }
  },
      [org_name]
  );
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
        <div className="d-block mb-4 mb-md-0">
          <p className="mb-0">
            {" "}
            <Button size="sm" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </p>
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHistory} />
            </Breadcrumb.Item>

            <Breadcrumb.Item active>My Transactions</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <PDFDownloadLink
              document={
                <LegderPDF
                  user={payer}
                  data={RECORDS.filter((_) => _.amount > 0)}
                />
              }
              fileName={`ledger-${moment().format("YYYYMMDDMMSS")}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    style={{ borderRadius: "10px 0px 0px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      size="sm"
                      className="me-2"
                    />
                    Export PDF
                  </Button>
                )
              }
            </PDFDownloadLink>

            <Button
              onClick={handleDownloadExcel}
              variant="outline-primary"
              size="sm"
            >
              Export Excel{" "}
              <FontAwesomeIcon icon={faDownload} size="sm" className="me-2" />
            </Button>
          </ButtonGroup>
        </div>
      </div>
      {/* {JSON.stringify(payer)} */}
      <div className="table-settings mb-4">
      <h4 className="text-center">
                            {user.role !== 'user' ? <Col md={12}>
                                <CustomTypeahead
                                    onInputChange={(e) => {
                                        setOrgName(e);
                                        dispatch({
                                            type: TAX_ACCOUNT,
                                            payload: {}
                                        });
                                    }}
                                    labelKey={(e) =>
                                        `${e.account_type === "org" ? e.org_name : e.name
                                        }(${e.taxID}) ${e.phone ? `:${e.phone || e.office_phone}` : ""
                                        }`
                                    }
                                    selected={tax_account.taxID ? [tax_account] : undefined}
                                    className="border border-primary rounded"
                                    onChange={(e) => {
                                        if (e && e[0]?.id) {
                                            setForm((p) => ({
                                                ...p,
                                                org_id: e[0]?.taxID,
                                                org_name: e[0]?.org_name,
                                                occupation: e[0]?.business_sector,
                                                business_sector: e[0]?.business_sector,
                                                org_type: e[0]?.org_type,
                                            }));
                                            dispatch({
                                                type: TAX_ACCOUNT,
                                                payload: e[0],
                                            });
                                            setOrgName('');
                                        }
                                    }}
                                    clearButton
                                    col={12}
                                    label={`SELECT TAXPAYERS`}
                                    options={taxPayers}
                                    placeholder="Search Name | TaxID | Phone No."
                                />
                            </Col> : <span> {tax_account?.org_name?.toUpperCase() || tax_account?.name?.toUpperCase()}(
                                {tax_account?.taxID})</span>}

                        </h4>
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <SearchBar
              filterText={filterText}
              onFilterTextChange={(val) => setFilterText(val)}
            />
          </Col>
          <Col xs={12} md={6} lg={9} xl={8} className="ps-md-0 text-end">
            <Row>
              <Col md={3}></Col>
              <Col>
                <DaterangeSelector
                  from={form.from}
                  to={form.to}
                  handleChange={handleChange}
                  inline
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <TransactionsTable
        setRef={() => {}}
        viewOnly={viewOnly}
        _ref={tableRef}
        data={filtered_trx
          ?.map((t) => ({
            ...t,
            amount: Math.max(t.cr, t.dr)
          }))
          .filter((_) => _.amount)}
        user={payer}
      />
    </>
  );
};
