import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBook,
    faBoxOpen,
    // faChartPie,
    faCog,
    // faFileAlt,
    faHandHoldingUsd,
    faSignOutAlt,
    // faTable,
    faTimes,
    faLock,
    faUserFriends,
    // faCalendarAlt,
    // faMapPin,
    // faEject,
    // faRocket,
    faCreditCard,
    faHome,
    faListAlt,
    faUser,
    faWallet,
    faBars,
    faCertificate,
    faTable,
    faChevronUp,
    faChevronDown,
    faFile,
    faFileArchive,
    faFileUpload,
    faTablets,
} from '@fortawesome/free-solid-svg-icons';
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../routes';
import ReactHero from '../assets/img/kigra.jpg';
import ProfilePicture from '../assets/img/team/profile-picture-3.jpg';
import { logout } from '../redux/action/auth';
import useIsPhoneSize from '../utils/useIsPhoneSize';
import { faContao } from '@fortawesome/free-brands-svg-icons';
import { FaCertificate, FaClipboardList } from 'react-icons/fa';
// import { faAlgolia, faDashcube, faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faAlgolia, faDashcube, faPaypal } from '@fortawesome/free-brands-svg-icons';

export default (props = {}) => {
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? 'show' : '';
    const onCollapse = () => setShow(!show);
    const { extension, links } = props;
    const dispatch = useDispatch();
    const isPhoneSize = useIsPhoneSize();

    const { user } = useSelector((s) => s.auth);
    // useEffect(()=>{
    //   if(!user?.user_id){
    //     dispatch(logout(history))
    //   }
    // },[user?.user_id])

    // const CollapsableNavItem = (props) => {
    //   const { eventKey, title, icon, children = null } = props;
    //   const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    //   return (
    //     <>
    //       <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
    //         <Accordion.Item eventKey={eventKey}>
    //           <Accordion.Button
    //             as={Nav.Link}
    //             className="d-flex justify-content-between align-items-center"
    //           >
    //             <span>
    //               <span className="sidebar-icon">
    //                 <FontAwesomeIcon icon={icon} size="" />
    //               </span>
    //               <span className="sidebar-text">{title}</span>
    //             </span>
    //           </Accordion.Button>
    //           <Accordion.Body className="multi-level">
    //             <Nav className="flex-column">{children}</Nav>
    //           </Accordion.Body>
    //         </Accordion.Item>
    //       </Accordion>
    //     </>
    //   );
    // };
    const CollapsableNavItem = (props) => {
        const { eventKey, title, icon = faCog, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

        // State to track if the item is expanded
        const [expanded, setExpanded] = useState(defaultKey === eventKey);

        // Function to toggle the expanded state
        const handleToggle = () => {
            setExpanded(!expanded);
        };

        return (
            <Nav.Item>
                <Nav.Link
                    eventKey={eventKey}
                    onClick={handleToggle}
                    className="d-flex justify-content-between align-items-center"
                >
                    {/* <p> */}
                    {/* <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} size="sm" />
            </span> */}
                    <span className="sidebar-text">{title}</span>
                    {/* </p> */}
                    <span className="expand-icon">
                        {/* <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              size="sm"
            /> */}
                    </span>
                </Nav.Link>
                {/* {expanded && ( */}
                <div style={{ paddingLeft: '10px' }}>
                    <Nav className="flex-column">{children}</Nav>
                </div>
                {/* )} */}
            </Nav.Item>
        );
    };

    const NavItem = (props) => {
        const {
            title,
            link,
            external,
            target,
            icon,
            image,
            badgeText,
            badgeBg = 'secondary',
            badgeColor = 'primary',
            imageHeight,
            imageWidth,
        } = props;
        const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '';
        const navItemClassName = link === pathname ? 'active' : '';
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? (
                            <span className="sidebar-icon">
                                <FontAwesomeIcon icon={icon} />{' '}
                            </span>
                        ) : null}
                        {image ? (
                            <Image
                                src={image}
                                width={imageHeight || 20}
                                height={imageWidth || 20}
                                className="sidebar-icon svg-icon"
                            />
                        ) : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">
                            {badgeText}
                        </Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    const sector = (item) => user.sector.includes(item);

    const gotoSector = () => {
        switch (sector) {
            case 'NON TAX':
                history.push(Routes.NonTaxPayment.path);
                break;
            case 'TAX':
                history.push(Routes.TaxPayment.path);
                break;
            case 'VEHICLES':
                history.push(Routes.VehiclePayments.path);
                break;
            case 'LGA':
                history.push(Routes.LgaPayments.path);
                break;
            case 'LAND':
                history.push(Routes.LandPayments.path);
                break;
            default:
                history.push(Routes.Selection.path);
                break;
        }
    };

    return (
        <>
            {isPhoneSize ? (
                <center
                    className="mobile-bottom-nav"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 50,
                    }}
                >
                    <div
                        onClick={() => {
                            history.push(Routes.Selection.path);
                        }}
                        size="sm"
                        className="m-1"
                    >
                        <FontAwesomeIcon icon={faHome} className="me-1" style={{ fontSize: '20px' }} /> <br />
                        Home
                    </div>
                    <div
                        onClick={() => {
                            history.push(Routes.UserProfile.path);
                        }}
                        size="sm"
                        className="m-1"
                    >
                        <FontAwesomeIcon icon={faUser} className="me-1" style={{ fontSize: '20px' }} /> <br />
                        Profile
                    </div>

                    <div
                        onClick={() => {
                            history.push(Routes.UserTransactions.path);
                        }}
                        size="sm"
                        className="m-1"
                    >
                        <FontAwesomeIcon icon={faListAlt} style={{ fontSize: '20px' }} />
                        <br />
                        Transaction
                    </div>
                    <div
                        size="sm"
                        className="m-1"
                        onClick={() => {
                            history.push(Routes.ExpressAcct2.path);
                        }}
                    >
                        <FontAwesomeIcon icon={faBars} className="me-1" style={{ fontSize: '20px' }} />
                        <br />+ Accounts
                    </div>
                    <div
                        size="sm"
                        className="m-1"
                        onClick={() => {
                            history.push(Routes.NewResetPassword.path);
                        }}
                    >
                        <FontAwesomeIcon icon={faCog} className="me-1" style={{ fontSize: '20px' }} />
                        <br />
                        Reset Password
                    </div>
                    {/* <div
            className="mt-1 mb-1"
            size="sm"
            onClick={() => dispatch(logout(history))}
            color="danger"
          >
            <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "20px" }} />{" "}
            <br />
            Logout
          </div> */}
                </center>
            ) : (
                <Navbar expand={false} collapseOnSelect variant="light" className="navbar-theme-primary px-4 d-md-none">
                    <Navbar.Brand
                        className="me-lg-5"
                        as={Link}
                        to={extension ? Routes.Selection.path : Routes.DashboardOverview.path}
                    >
                        <Image src={ReactHero} className="navbar-brand-light" />
                    </Navbar.Brand>
                    <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                        <span className="navbar-toggler-icon" />
                    </Navbar.Toggle>
                </Navbar>
            )}
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                    <div className="sidebar-inner px-4 pt-3 bg-dark">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                                </div>
                                <div className="d-block">
                                    <h6>Hi, {user.name}</h6>
                                    <Button
                                        as={Link}
                                        variant="secondary"
                                        size="xs"
                                        to={Routes.Signin.path}
                                        className="text-dark"
                                    >
                                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                                    </Button>
                                </div>
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 pt-md-0">
                            <NavItem title="" link={'/'} image={ReactHero} imageHeight={150} imageWidth={150} />

                            {user.account_type === 'org' || user.role !=='user' ? (
                                <NavItem title="Dashboard" icon={faDashcube} link={Routes.OrgDashboard.path} />
                            ) : (
                                <NavItem title="Dashboard" icon={faDashcube} link={Routes.UserDashboard.path} />
                            )}
                            <>
                                <Dropdown.Divider className="my-3 border-indigo" />
                                {user.account_type === 'org' || user.role !=='user' ? (
                                    <CollapsableNavItem title="PAY AS YOU EARN" icon={faCertificate}>
                                        <NavItem title="Manage Staff" icon={faUser} link={Routes.OrgStaff.path} />
                                        <NavItem
                                            title="Paye Remitance"
                                            icon={faFileArchive}
                                            link={Routes.NominalRollUpload.path}
                                        />
                                        <NavItem
                                            title="PAYE Liability"
                                            icon={faCertificate}
                                            link={Routes.PendingTax.path}
                                        />

                                        {/* <NavItem
                                            title="Employee Registration"
                                            icon={faUserFriends}
                                            link={Routes.EmployeeRegistration.path}
                                        /> 
                                        <NavItem
                                            title="Upload Payroll "
                                            icon={faFileUpload}
                                            link={Routes.UploadPayroll.path}
                                        />
                                        <NavItem title="Paygroup Setup" icon={faCog} link={Routes.PayGroup.path} />
                                        <NavItem
                                            title="Organization"
                                            icon={faUserFriends}
                                            link={Routes.Organization.path}
                                        />*/}
                                    </CollapsableNavItem>
                                ) :user.account_type !== 'org'?  (
                                    <>
                                        <CollapsableNavItem title="DIRECT ASSESSMENT" icon={faCertificate}>
                                            <NavItem title="Filling" icon={faFileArchive} link={Routes.Filling.path} />
                                        </CollapsableNavItem>
                                    </>
                                ):null}
                                <Dropdown.Divider className="my-3 border-indigo" />
                                <CollapsableNavItem title="WITHHOLDINGS" icon={faTable}>
                                    <NavItem title="Filing" icon={faFileArchive} link={Routes.FilingWithholding.path} />
                                    <NavItem
                                        title="Raised WHT"
                                        icon={faHandHoldingUsd}
                                        link={Routes.Withholding.path}
                                    />
                                </CollapsableNavItem>
                                <Dropdown.Divider className="my-3 border-indigo" />
                                <CollapsableNavItem title="ACCOUNT" icon={faTable}>
                                    <NavItem title="Profile" icon={faUser} link={Routes.UserProfile.path} />
                                    <NavItem
                                        title="Add Tax Account"
                                        icon={faUserFriends}
                                        link={Routes.ExpressAcct2.path}
                                    />
                                    <NavItem
                                        title="Ledger"
                                        icon={faHandHoldingUsd}
                                        link={Routes.UserTransactions.path}
                                    />
                                    {/* <NavItem title="Pending Tax" icon={faCertificate} link={Routes.PendingTax.path} /> */}
                                    {/* {user.account_type === 'org' ? (
                                        <>
                                            <NavItem title="Paygroup Setup" icon={faCog} link={Routes.PayGroup.path} />
                                            <NavItem
                                                title="Organization"
                                                icon={faUserFriends}
                                                link={Routes.Organization.path}
                                            />
                                        </>
                                    ) : null} */}
                                </CollapsableNavItem>

                                <Dropdown.Divider className="my-3 border-indigo" />
                                <CollapsableNavItem title="OTHER LINKS" icon={faTable}>
                                    <NavItem title="Generate Invoice" icon={faTablets} link={Routes.Selection.path} />
                                    <NavItem title="Pay Invoice" icon={faPaypal} link={Routes.PayInvoice1.path} />
                                    <NavItem title="Verify PayNow" icon={faAlgolia} link={Routes.VerifyPayNow.path} />
                                    <NavItem
                                        title="Engage Consultant"
                                        icon={faContao}
                                        link={Routes.ViewConsultant.path}
                                    />
                                    <NavItem title="File Tax Clearance" icon={faCertificate} link={Routes.TaxClearancePublic.path} />
                                    <NavItem
                                        title="Reset Password"
                                        icon={faHandHoldingUsd}
                                        link={Routes.NewResetPassword.path}
                                    />
                                </CollapsableNavItem>

                                <Dropdown.Divider className="my-3 border-indigo" />
                                <Button className="bg-danger" onClick={() => dispatch(logout(history))} color="red">
                                    <FontAwesomeIcon icon={faLock} /> Logout
                                </Button>
                            </>
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};
