import React, { useState } from "react";
import CustomInput from "../examples/CustomInput";
import { CustomButton } from "../../components/UI";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { Alert, Card, Modal } from "@themesberg/react-bootstrap";
import { Button, Col, Row, Spinner } from "reactstrap";
import { _fetchApi, apiURL } from "../../redux/action/api";
import { useSelector } from "react-redux";
import ReceiptPreview from "../../components/ReceiptPreview";
import { isSuper } from "../../components/UI/helpers";

function GenerateReceipt() {
  const { user } = useSelector((p) => p.auth);
  const [refNo, setRefNo] = useState("");
  const history = useHistory();
  const [showDefault, setShowDefault] = useState(false);
  const toggle = () => setShowDefault(!showDefault);
  const [loading, setLoading] = useState(false);
  const [qrcode, setQrCode] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const displayReceipt = () => {
    if (refNo && refNo !== "") {
      history.push(`${Routes.adminPaymentReceipt.path}?ref_no=${refNo}`);
    } else {
      // alert
    }
  };

  const getReceiptData = () => {
    setLoading(true);
    if (refNo && refNo != "") {
      _fetchApi(
        `/transactions/retrieve?query_type=paid_invoice&ref_no=${refNo}&user_id=${user?.id}`,
        (resp) => {
          if (resp.success && resp.data && resp.data.length) {
            setLoading(false);
            setData(resp.data.filter((tax) => tax.reference_number === refNo));
            setShowDefault(true);
            setError("");
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
          setError("Operation failed");
        }
      );
      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${refNo}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            setError("Error occured");

            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
          setError("");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setError("Record not found");
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="d-flex p-2  justify-content-center flex-wrap flex-md-nowrap align-items-center py-4 pt-8">
        <Card className="shadow col-md-6 py-5">
          <Card.Body className="text-center p-4 ">
            <div className="mb-4 mb-md-0">
              <h4>GENERATE RECEIPT</h4>
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Enter Reference Number"
                value={refNo}
                className="text-center"
                onChange={({ target: { value } }) => setRefNo(value)}
              />
            </div>
            {error ? (
              <Alert>
                <p>
                  <strong className="text-danger">Notice</strong> {error}
                </p>
              </Alert>
            ) : (
              ""
            )}
            <CustomButton loading={loading} onClick={getReceiptData}>
              Preview
            </CustomButton>
          </Card.Body>
        </Card>
      </div>
      <Modal as={Modal.Dialog} centered show={showDefault} size="lg">
        {loading ? (
          <Spinner />
        ) : (
          <div>
            <Modal.Header>
              <Modal.Title className="h6">Reciept preview</Modal.Title>
              <Button
                className="bg-danger"
                aria-label="Close"
                onClick={() => setShowDefault(false)}
              >
                X
              </Button>
            </Modal.Header>
            <Modal.Body>
              <ReceiptPreview qrcode={qrcode} data={data} />
            </Modal.Body>
            <hr />
            <Row>
              <Col className="">
                <Button
                  onClick={() => setShowDefault(false)}
                  color="danger"
                  className="ms-auto"
                >
                  Cancel
                </Button>
              </Col>

              <Col className=" text-center">
                {data[0]?.printed && user.rank === "Department Head" ? (
                  <CustomButton
                    loading={loading}
                    color="info"
                    disabled={
                      data[0]?.printed && user.rank !== "Department Head"
                    }
                    onClick={() =>
                      history.push(
                        `${Routes.PaymentLogs.path}?ref_no=${data[0]?.reference_number}`
                      )
                    }
                  >
                    View Log
                  </CustomButton>
                ) : (
                  ""
                )}
              </Col>

              <Col className=" text-right right">
                {data[0]?.printed && user.rank === "Department Head" || isSuper(user) ? (
                  <CustomButton
                    loading={loading}
                    
                    onClick={displayReceipt}
                  >
                    Re-print
                  </CustomButton>
                ) : !data[0]?.printed ? (
                  <CustomButton loading={loading} onClick={displayReceipt}>
                    Print
                  </CustomButton>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      {/* {ref_no && ref_no !== "" ?  <PaymentInvoice refNo={refNo} /> : null} */}
    </div>
  );
}

export default GenerateReceipt;
