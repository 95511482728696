import React, { useEffect, useState } from "react";
import CustomDateRange from "./CustomDateRange";
import { _fetchApi } from "../../redux/action/api";
import moment from "moment";
import { CounterWidget } from "../../components/Widgets";
import { Alert, Col, Row } from "reactstrap";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatNumber } from "../../utils";
import BarChartComponent from "./charts/BarChartComponent";
import { CURRENCY } from "../../components/Tables2";
import { Card } from "@themesberg/react-bootstrap";
function MDAsRevCollectionReport() {


  // date picker setup
  const today = moment();
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    _fetchApi(
      `/rev-collection-report-history?date_from=${dateRange.from}&date_to=${dateRange.to}&query_type=NON TAX Report`,
      (res) => {
        if (res.success) {
          setData(res.results);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, [dateRange.from, dateRange.to]);


  return (
    <div style={{ margin: "5px" }}>
      <h4 className="py-3 text-center">Revenue Collection Report</h4>
      <CustomDateRange
        type={dateRange.type}
        month={dateRange.month}
        quarter={dateRange.quarter}
        year={dateRange.year}
        from={dateRange.from}
        to={dateRange.to}
        handleChangeDate={handleChangeDate}
      />
      <h4 className="py-3 text-right">Total Revenue: {CURRENCY}{formatNumber(data?.reduce((it, id) => it + parseFloat(id?.amount), 0))}</h4>

      <Row>
        {data?.length ? (
          data
            ?.filter((item) => parseInt(item.amount) > 0)
            ?.map((item, index) => (
              <Col className="mb-4 mt-2" key={item.sector}>
                <CounterWidget
                  className="shadow"
                  color="light"
                  category={item.sector.replace("VEHICLES", "MLO").replace("NON TAX", "MDAs").replace("LAND", "LUC")}
                  title={item.amount}
                  period={
                    `${moment(dateRange.dateRange).format("MMMM D")}` -
                    `${moment(dateRange.to).format("MMMM D")}`
                  }
                  percentage={28.4}
                  icon={faChartLine}
                  iconColor="shape-tertiary"
                  showChanges={false}
                  onClick={() =>
                    history.push(
                      `${Routes.RevenueCollectionReportView.path}?date_from=${dateRange.from}&date_to=${dateRange.to}&sector=${item.sector}`
                    )
                  }
                />
              </Col>
            ))
        ) : (
          <Alert color="info" className="text-center">
            No records available, please adjust the date.
          </Alert>
        )}
      </Row>
 
      <Card>
      <Card.Body className="text-center">
      <BarChartComponent
              data={data
                ?.filter((x) => x.sector && x.amount > 0)
                ?.map((x) => ({
                  ...x,
                  Collections: x?.amount,
                  sector: x.sector.replace("VEHICLES", "MLO").replace("NON TAX", "MDAs").replace("LAND", "LUC"),
                }))}
              bars={[
                { title: "Collections", label: true },
                // { title: '', label: true },
              ]}
            /> 
      </Card.Body>
      </Card>
    </div>
  );
}

export default MDAsRevCollectionReport;
