import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { _fetchApi } from "../../redux/action/api";
import DaterangeSelector from "../dashboard/DaterangeSelector";
import { formatNumber, useQuery } from "../../utils";
import toast from "react-hot-toast";
import CustomModal from "../../components/UI/CustomModal";
import { Card, Image, Nav, NavItem } from "@themesberg/react-bootstrap";
// import GeneralCart from "../components/GeneralCart";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { TAX_ACCOUNT } from "../../redux/action/type";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faFileArchive, faHandHolding, faPlus, faSdCard, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import NewCart from "../components/NewCart";
import { Routes } from "../../routes";
import PaymentsContainerNew from "./components/PaymentsContainerNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SelectInput from "../components/SelectInput";
import { faCcMastercard } from "@fortawesome/free-brands-svg-icons";

export default function MDARevenue() {
  const q = useQuery()
  const mda_name = q.get('mda_name')
  const sector = q.get('sector')
  const _form = {
    type: mda_name?.includes('Revenue Service') ? "TAX Revenue" : "MDAs Charges, Fees & Levies",
    amount: "",
    sector: sector ?? "NON TAX",
    tax_code: "",
    tax_parent_code: "",
    mda_name: mda_name ?? "",
    from: "",
    to: "",
    phone: "",
    invoice_status: "Full payment",
    department: "",
  };

  const [form, setForm] = useState(_form);
  const [MDAList, setMDAList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currTax, setCurrTax] = useState({});
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);
  const KIRS = 'Kano State Internal Revenue Service'
  const MDAs_Type = 'MDAs Charges, Fees & Levies'
  const [tax_payers, setTaxPayers] = useState([]);
  const [tax_payer, setTapayer] = useState("");
  const isPhoneSize = useIsPhoneSize();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
  const { tax_account, tax_accounts = [], user } = useSelector((p) => p.auth);
  const toggle = (type) => {
    setIsOpen(!isOpen);
  };
  const myRef = useRef()
  const getTaxPayers = useCallback(() => {
    if (tax_payer.length > 2) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    } else if (tax_payer.length === 0) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    }
  }, [tax_payer]);

  useEffect(() => {
    getTaxPayers();
  }, [getTaxPayers]);

  const getList2 = useCallback(() => {
    if (form.mda_name) {
      setLoading(true);
      _fetchApi(
        `/kigra-taxes?query_type=plat-mda-taxes&mda_name=${form.mda_name}&sector=${form.sector}`,
        (resp) => {
          if (resp.result) {
            setTaxList(resp.result);
            setLoading(false);
          }
        },
        (err) => {
          setTaxList([]);
          console.error(err);
          setLoading(false);
        }
      );
    }
  }, [form.mda_name, form.sector]);

  useEffect(() => {
    getList2();
  }, [getList2]);

  useEffect(() => {
    _fetchApi(
      `/kigra-taxes?query_type=mda-list&sector=${form.sector}`,
      (resp) => {
        if (resp.result) {
          setMDAList(resp.result);
          setLoading(false);
        }
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, [form.sector]);

  const handleDateRangeChange = ({ target: { name, value } }) => {
    if (name === "from") {
      setForm((p) => ({ ...p, from: value }));
    } else if (name === "to") {
      setForm((p) => ({ ...p, to: value }));
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    // alert(JSON.stringify(name, value))
    setForm((s) => ({ ...s, [name]: value }));
  };

  const handleChangeType = ({ target: { name, value } }) => {
    // alert(JSON.stringify({ name, value }))
    // const NMDAList = value?.includes('MDAs') ? setMDAList((s) => (MDAList.filter(mda => mda?.mda_name?.includes('Revenue')))) : MDAList
    // setMDAList(NMDAList)
    setForm((s) => ({
      ...s, [name]: value,
      mda_name: value === 'TAX Revenue' ? KIRS : form.mda_name,
      // page_title: value?.includes('MDAs') ? 'MDAs Charges, Fees & Levies' : 'Tax Revenue',
      sector: value === 'TAX Revenue' ? 'TAX' : 'NON TAX'
    }));
    getList2();
  };


  const handleTaxFeeChecked = () => {
    // Validate form fields
    if (!form.mda_name || !form.tax_fee || !form.from || !form.to) {
      toast.error("Please fill all fields before adding.");
      return;
    }
    setCurrTax({})
    getList2()
    // Add current form data to the selectedTaxes array
    setSelectedTaxes((prev) => [...prev, { ...form, checked: true }]);

    // Reset the form fields to initial state
    setForm(_form);
  };

  const proceed = () => {
    return form.from && form.to && form.mda_name && parseFloat(form.tax_fee) > 0 && form.title
  };

  const handleFocus = (name) => {
    setTapayer(name);
    setTimeout(() => {
      toast.success("You can now close and search the tax payer by name or ID");
      toggleModal();
    }, 2000);
  };

  //   const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);
  const history = useHistory()
  const renderFileNo = () => {
    return (
      <Row>
        <Col md={4}>
          <Label>File name</Label>{" "}
          <Input
            type="text"
            name="file_name"
            onChange={handleChange}
            style={{ width: "100%" }}
            value={form.file_name}
          />
        </Col>
        <Col md={4}>
          <Label>File No.</Label>{" "}
          <Input
            type="text"
            name="mda_val"
            onChange={({ target: { value } }) =>
              setForm((p) => ({
                ...p,
                mda_val: value,
                mda_var: "File No.",
              }))
            }
            style={{ width: "100%" }}
            value={form.mda_val}
          />
        </Col>
        <Col md={4}>
          <Label>Payment status</Label>{" "}
          <Input
            type="select"
            name="invoice_status"
            onChange={({ target: { value } }) =>
              setForm((p) => ({
                ...p,
                invoice_status: value,
              }))
            }
            style={{ width: "100%" }}
            value={form.invoice_status}
          >
            {["Full payment", "Part payment", "Balance payment"].map(
              (status, idx) => (
                <option key={idx}>{status}</option>
              )
            )}
          </Input>
        </Col>
      </Row>
    );
  };

  const getDepartments = (data) => {
    // Check if any department exists in the data
    if (data.some(item => item.department)) {
      // Extract unique department names
      return [...new Set(data.map(item => item.department))];
    }
    return [];
  };

  return (
    <PaymentsContainerNew
      //   handleAddList={handleAddList}
      open={open}
      openModal={openModal}
      //   form={form}
      menu={<div style={{
        marginLeft: '10px',
        textAlign: 'left'

      }}>
        <br />   <br />

        <br />
        <Button
          className="p-2 text-left"
          size='sm'
          onClick={() =>{
            setForm((p)=>({...p,
              mda_name:KIRS,
              sector:'TAX Revenue',
              type:'TAX Revenue',
            }))
          }}
          block
        > <FontAwesomeIcon icon={faCreditCard} /> Tax Revenue</Button>
          <br />
        <Button
          className="p-2 text-left"
          size='sm'
          onClick={() =>{
            setForm((p)=>({...p,
              mda_name: '',
              sector: 'NON TAX',
              type: MDAs_Type
            }))
          }}
          block
        > <FontAwesomeIcon icon={faCreditCard} />MDAs Charges, Levies & Fees</Button>
        <br />
        <Button className="p-2 text-left"
          onClick={() => history.push(Routes.NominalRollUpload.path)}
          size='sm' block> <FontAwesomeIcon icon={faUserAlt} /> Paye Remitance</Button>
            <br />
            <Button className="p-2 text-left"
          onClick={() => history.push(Routes.Filling.path)}
          size='sm' block> <FontAwesomeIcon icon={faCcMastercard} /> Direct Assesment</Button>
            <br />
            <Button className="p-2 text-left"
          onClick={() => history.push(Routes.FilingWithholding.path)}
          size='sm' block> <FontAwesomeIcon icon={faHandHolding} /> Withholding Tax</Button>
      </div>}
    >
      <Card>
        <Card.Header>
          <h4 className="text-center">{form.type}</h4>
        </Card.Header>
        <Card.Body>
          <div className="">
            <div className="">
              <Row>
                <Label>Select Revenue Type</Label>
                <Input
                  name='type'
                  onChange={handleChangeType}
                  type="select">
                  <option
                    value={MDAs_Type}
                    selected={!form.mda_name.includes('Revenue Service')}
                  >{MDAs_Type}</option>
                  <option
                    value={'TAX Revenue'}
                    selected={form.mda_name.includes('Revenue Service')}>TAX Revenue</option>
                </Input>
              </Row>
              <h3>{loading && <Spinner />}</h3>

              {/* {JSON.stringify(form)} */}

              <Form onSubmit={toggle}>
                <DaterangeSelector from={form.from} to={form.to} handleChange={handleDateRangeChange} />
                <Row>
                  <Col md={12}>
                    {user.role !== "user" ? (
                      <Row>
                        <Col md={6} className="mb-2">
                          <CustomTypeahead
                            onInputChange={(e) => {
                              setTapayer(e);
                              dispatch({
                                type: TAX_ACCOUNT,
                                payload: {}
                              });
                            }}
                            _ref={myRef}
                            labelKey={(e) =>
                              `${e.account_type === "org" ? e.org_name : e.name
                              }(${e.taxID}) ${e.phone ? `:${e.phone}` : ""}`
                            }
                            onChange={(e) => {
                              if (e && e[0]?.id) {
                                dispatch({
                                  type: TAX_ACCOUNT,
                                  payload: e[0],
                                });
                              }
                            }}
                            // onInputChange={(e) => {
                            //   setTapayer(e);
                            // }}
                            clearButton
                            col={12}
                            label={`Select Tax payer`}
                            options={tax_payers}
                            selected={tax_account.taxID ? [tax_account] : undefined}
                            placeholder="Search Name | TaxID | Phone No."
                          />
                        </Col>
                        <Col>
                          <br />
                          <Button
                            onClick={() => toggleModal()}
                            className="bg-white mt-2 ml--2 text-dark"
                          >
                            <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <Label>Tax Payer </Label>{" "}
                          <Input
                            type="text"
                            disabled="disable"
                            value={
                              tax_account.account_type === "org"
                                ? tax_account.org_name
                                : tax_account.name
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  {form.type === 'TAX Revenue' ?
                    <Col>
                      <Input type="text" disabled value={KIRS} />
                    </Col> :
                    <Col md={12}>
                      <Label>Select MDA</Label>
                      <CustomTypeahead
                        labelKey={(e) => `${e.mda_name}`}
                        onInputChange={() => {
                          setForm((s) => ({ ...s, mda_name: '' }));
                        }}
                        onChange={(e) => {
                          // alert(JSON.stringify(e[0]))
                          if (e && e?.[0]?.mda_name) {
                            setForm((s) => ({ ...s, mda_name: e[0]?.mda_name }));
                            setCurrTax({})
                          }
                        }}
                        clearButton
                        col={12}
                        selected={form.mda_name ? [{ mda_code: form.mda_code, mda_name: form.mda_name }] : null}
                        options={MDAList}
                        placeholder="Search MDA by Name"
                      />
                    </Col>}
                </Row>
                {getDepartments(taxList).length ?
                  <Col md={12}>
                    <Label>Select Department</Label>
                    <SelectInput
                      col={12}
                      options={getDepartments(taxList).map(department => department)}
                      value={form.department}
                      name='department'
                      onChange={handleChange}
                      placeholder="Select Department"
                    />
                  </Col>
                  : null
                }
                <Row className="mt-3">
                  <Col md={12}>
                    <Label>Select Revenue Description</Label>
                    <CustomTypeahead
                      labelKey={(e) => `${e.title}`}
                      onInputChange={() => {
                        setForm((s) => ({ ...s, tax_code: '', title: '', economic_code: '' }));
                        setCurrTax({})
                      }}
                      onChange={(e) => {
                        if (e && e[0]?.title) {
                          setForm((p) => ({ ...p, ...e[0] }));
                          setCurrTax(e[0])
                        }
                      }}
                      clearButton
                      col={12}
                      options={form.department ? taxList.filter(x => x.department === form.department) : taxList}
                      selected={currTax.id ? [currTax] : null}
                      value={currTax.id ? [currTax] : null}
                      placeholder="Search Revenue Description"
                    />
                  </Col>
                  {form.mda_name?.includes('Land') ? renderFileNo() : null}
                  <Col className="" md={6}>
                    <Label>Amount</Label>
                    <Input
                      placeholder={currTax.tax_fee && currTax.tax_fee !== '0.00' ? "Enter Amount" : currTax.tax_fee}
                      type="number"
                      value={form.tax_fee}
                      name={"tax_fee"}
                      onChange={handleChange}
                      disabled={currTax.tax_fee && currTax.tax_fee !== '0.00'}
                    />
                  </Col>



                  {/* {proceed()?<Col className="pt-3 mt-0 text-center">
                            <Button color="primary" onClick={handleTaxFeeChecked}>
                                Add more items
                            </Button>
                        </Col>:null} */}
                </Row>
              </Form>

              {/* Table to render selectedTaxes */}
              {selectedTaxes.length ? <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>MDA Name</th>
                    <th>Tax Description</th>
                    <th>Amount</th>
                    <th>From</th>
                    <th>To</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTaxes.length > 0 ? (
                    selectedTaxes.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.mda_name}</td>
                        <td>{item.title}</td>
                        <td>{formatNumber(item.tax_fee)}</td>
                        <td>{item.from}</td>
                        <td>{item.to}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No items added yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table> : null}
              {(proceed() || selectedTaxes.length) ? <Col md={12} className="pt-3 mt-0 text-center">
                <Button color="warning" onClick={toggle}>
                  Proceed to Payment
                </Button>
              </Col> : null}
            </div>

            <CustomModal
              logo={
                <center className="p-0 text-center">
                  <Image
                    src={require("../../assets/img/kigra.jpg")}
                    className="img-fluid"
                    alt="Logo"
                    style={{
                      borderRadius: "64px",
                      height: isPhoneSize ? "10%" : "20%",
                      width: isPhoneSize ? "10%" : "20%",
                    }}
                  />
                </center>
              }
              size="lg"
              toggle={toggle}
              isOpen={isOpen}
            >
              {/* {JSON.stringify(selectedTaxes)} */}
              <NewCart
                form={{ ...form }}
                data={selectedTaxes.length ? selectedTaxes : [{ ...form, checked: true }]}
                setModal={setIsOpen}
                handleTaxFeeChecked={() => { }}
              />
            </CustomModal>
          </div>
        </Card.Body>
      </Card>
    </PaymentsContainerNew>

  );
}
