import React from "react";
import { FormGroup, Input, Row } from "reactstrap";
import { Col, Button, ButtonGroup } from "@themesberg/react-bootstrap";
import moment from "moment";

export default function CustomDateRange({
  type = "",
  month = "",
  quarter = "",
  year = "",
  from = "",
  to = "",
  handleChangeDate = (f) => f,
}) {
  const dateRanges = [
    { type: "daily", label: "Daily" },
    { type: "weekly", label: "Weekly" },
    { type: "monthly", label: "Monthly" },
    { type: "quarterly", label: "Quarterly" },
    { type: "yearly", label: "Yearly" },
    { type: "customm", label: "Custom" },
  ];

  return (
    <Row >
      <Col md={6}>
        <ButtonGroup>
          {dateRanges.map((r) => (
            <Button
              key={r.type}
              name={"type"} // Make sure 'name' is set to the expected value
              value={r.type} // Make sure 'name' is set to the expected value
              onClick={handleChangeDate}
              variant={type === r.type ? "dark" : "light"}
            >
              {r.label}
            </Button>
          ))}
        </ButtonGroup>
      </Col>
      <Col md={6}>
        {type === "monthly" && (
          <Row>
            <Col md={6} className="offset-md-6">
              <FormGroup>
                <Input
                  type="select"
                  name="month"
                  value={month}
                  onChange={handleChangeDate}
                >
                  {<option value={month??""}>{month?moment(month, 'MM').format('MMM'):'Select Month'}</option>}
                  {moment.months().map((mon, index) => (
                    <option key={index} value={index + 1}>
                      {mon}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}
        {type === "quarterly" && (
          <Row className="d-flex mx-0 px-0 flex-direction-row justify-content-between">
            <Col md={6}>
              <FormGroup>
                <Input
                  type="select"
                  name="year"
                  value={year}
                  onChange={handleChangeDate}
                >
                  <option value="">Select Year</option>
                  {Array.from(
                    { length: new Date().getFullYear() - 2021 },
                    (_, index) => 2022 + index
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Input
                  value={quarter}
                  type="select"
                  name="quarter"
                  onChange={handleChangeDate}
                >
                  <option value={quarter} >Select Quarter</option>
                  <option value="Q1">Q1</option>
                  <option value="Q2">Q2</option>
                  <option value="Q3">Q3</option>
                  <option value="Q4">Q4</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}
        {type === "yearly" && (
          <Row>
            <Col className="offset-md-6" md={6} >
              <FormGroup>
                <Input
                  type="select"
                  name="year"
                  value={year}
                  onChange={handleChangeDate}
                >
                  <option value="">Select Year</option>
                  {Array.from(
                    { length: new Date().getFullYear() - 2021 },
                    (_, index) => 2022 + index
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        )}
        {
          ["customm", "daily", "weekly"].includes(type) && (
            <Row className="d-flex mx-0 px-0 flex-direction-row justify-content-between">
              <Col md={5}>
                <FormGroup>
                  <Input
                    type="date"
                    value={from}
                    name="from"
                    onChange={handleChangeDate}
                  />
                </FormGroup>
              </Col>

              <Col md={5}>
                <FormGroup>
                  <Input
                    type="date"
                    value={to}
                    name="to"
                    onChange={handleChangeDate}
                  />
                </FormGroup>
              </Col>
            </Row>
          )
        }
      </Col >
    </Row >
  );
}
