import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  Text,
} from "recharts";
import "./customChart.css";
import { useHistory } from "react-router-dom";

const BarChartComponent = ({ data, width = 700, height = 400 }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the hovered bar index

  const formatYTick = (value) => {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + "T";
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    }
    return value;
  };

  const formatXTick = (value) => {
    const maxLength = 12;
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + "...";
    }
    return value;
  };

  // Calculate the sum of the "Collections" values
  const totalCollections = data?.reduce((sum, item) => sum + item.Collections, 0);

  // Colors array for unique bar colors
  const colors = [
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#33FFF5",
    "#FF9933",
    "#9933FF",
    "#33CCFF",
    "#FF5733",
  ];
  const history = useHistory()
  return (
    <div>
      <BarChart data={data} width={width} height={height}>
        {/* <XAxis dataKey="sector" tickFormatter={formatXTick} /> */}
        <YAxis tickFormatter={formatYTick} yAxisId="left" />
        {/* <Tooltip formatter={formatYTick} /> */}
        <Legend />
        {/* Display total collections at the top center */}
        <Text
          x={width}
          y={50}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={16}
          fill="#333"
        >
          Total Collections: {formatYTick(totalCollections)}
        </Text>
        {/* Render each bar with hover effect */}
        {data?.map((entry, index) => (
          <Bar
            key={index}
            yAxisId="left"
            dataKey="Collections"
            data={[entry]} // Each bar gets its own data slice
            fill={colors[index % colors.length]}
            name={entry.sector}
            onClick={()=>entry.link?history.push(entry.link):()=>{}}
             // Set sector as the name in the legend
            // opacity={hoveredIndex === index || hoveredIndex === null ? 1 : 0.1} // Set opacity based on hover state
            // onMouseEnter={() => setHoveredIndex(index)} // Set hovered index on mouse enter
            // onMouseLeave={() => setHoveredIndex(null)} // Reset hover state on mouse leave
          >
            <LabelList
              dataKey="Collections"
              position="top"
              formatter={formatYTick}
            />
          </Bar>
        ))}
      </BarChart>
    </div>
  );
};

export default BarChartComponent;
